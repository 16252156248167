import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'antd';
import styles from './index.less';

const YOUTUBE_IFRAME = 'https://www.youtube.com/iframe_api';
const scriptId = 'youtube-script';

async function loadYoutubePlayerPlugin(id) {
  const scriptTag = document.getElementById(scriptId);

  if (scriptTag) {
    Promise.resolve();
    return;
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = function () {
      typeof resolve === 'function' && resolve();
    };
    script.onerror = reject;
    script.src = YOUTUBE_IFRAME;
    script.id = id;
    document.body.append(script);
  });
}

function initialYoutube(playerId, videoId) {
  new window.YT.Player(playerId, {
    videoId,
    width: '100%',
    height: '100%',
    playerVars: {
      autoplay: 1,
      controls: 1,
      loop: 1,
      disablekb: 1,
      enablejsapi: 1,
      playsinline: 0,
      'webkit-playsinline': 1,
      modestbranding: 1,
      iv_load_policy: 3,
      rel: 0,
    },
    events: {
      onReady: (e) => {
        e.target.playVideo();
      },
    },
  });
}

export const PlayerInModal = ({ onClose, playerId, videoId }) => {
  const ref = useRef();

  async function loadVideo(playerId, videoId) {
    try {
      loadYoutubePlayerPlugin(scriptId);
      if (window.YT && window.YT.Player) {
        initialYoutube(playerId, videoId);
      } else {
        window.onYouTubeIframeAPIReady = function () {
          initialYoutube(playerId, videoId);
          window.onYouTubeIframeAPIReady = null;
        };
      }
    } catch (e) {
      console.log('init player error: ', e);
    }
  }

  useEffect(() => {
    loadVideo(playerId, videoId);
  }, [playerId, videoId]);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;
    const { height, width } = element.getBoundingClientRect();
    const parent = element.closest('.ant-modal-content');
    if (!parent) return;
    parent.style.height = `${(9 * width) / 16}px`;
  }, []);

  return (
    <Modal
      visible={true}
      footer={null}
      closable={false}
      width={1208}
      className={styles.VideoPlayer}
      onCancel={onClose}
      centered={true}
    >
      <div className="player-wrapper">
        <div className="video-container" ref={ref}>
          <div id={playerId} className="video-player" />
        </div>
        <div className="player-overlay" />
      </div>
    </Modal>
  );
};

const YoutubePlayer = ({ playerId, videoId, cover }) => {
  const [playerModalVisible, setPlayerModalVisible] = useState(false);

  const handlePlay = () => {
    setPlayerModalVisible(true);
  };

  const handleCloseModal = () => {
    setPlayerModalVisible(false);
  };

  return (
    <div className={styles.VideoPlayer}>
      <div className="video-container">
        <div className={`video-placeholder ${cover ? 'hasCover' : ''}`}>
          {cover ? <img className="video-cover" src={cover} alt="" /> : null}
        </div>
        <div className="play-btn" onClick={handlePlay} />
      </div>
      {playerModalVisible && <PlayerInModal onClose={handleCloseModal} playerId={playerId} videoId={videoId} />}
    </div>
  );
};

export default YoutubePlayer;
