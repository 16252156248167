import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Grid, Carousel } from 'antd';
import { useIntl } from 'react-intl';
import indexStyles from '../index.less';
import { JoinClassName } from 'src/utils/utils';
import CommonSection from '../CommonSection';
import CustomerVoiceInfos from 'src/consts/poswebsite_content/whyChooseChowbus';
import Link from 'next/link';
import useLocale from 'src/hooks/useLocale';
import UnoptimizedImage from 'src/components/UnoptimizedImage';
import { PlayerInModal } from 'src/components/YoutubePlayer';

const { useBreakpoint } = Grid;

const WhyChooseChowbus = ({
  showModel = 'default',
  forServiceType = false,
  customerCaseKeys,
  whyChooseChowbusTitle,
}) => {
  const intl = useIntl();
  const screens = useBreakpoint();
  const local = useLocale();
  const [willPlayYoutubeVideoId, setWillPlayYoutubeVideoId] = useState('');
  const [ativeCustomerVoiceCardIndex, setAtiveCustomerVoiceCardIndex] = useState(0);
  const [centerPadding, setCenterPadding] = useState(20);
  const onChangedActivedCustomerVoiceCardIndex = useCallback((current) => {
    setAtiveCustomerVoiceCardIndex(current);
  }, []);

  useEffect(() => {
    if (screens.xxl) {
      // 屏幕 ≥ 1600px
      //1600
      setCenterPadding(280);
    } else if (screens.xl) {
      //屏幕 ≥ 1200px
      //1200
      setCenterPadding(280);
    } else if (screens.lg) {
      //992
      //屏幕 ≥ 992px
      setCenterPadding(120);
    } else if (screens.md) {
      //xs	屏幕 < 576px 响应式栅格
      //576
      setCenterPadding(100);
    } else if (screens.sm) {
      //xs	屏幕 < 576px 响应式栅格
      //576
      setCenterPadding(50);
    } else if (screens.xs) {
      //xs	屏幕 < 576px 响应式栅格
      //576
      setCenterPadding(30);
    }
    //sm	屏幕 ≥ 576px
    //md 屏幕 ≥ 768px
  }, [screens]);

  const presentMdoel = useMemo(() => {
    return {
      lightGray_background: JoinClassName(indexStyles.lightGrayBackgroundcolor),
      default: '',
      lightGray: JoinClassName(indexStyles.lightGray),
    }[showModel];
  }, [showModel]);

  const customerVoiceInfos = useMemo(() => {
    if (
      !customerCaseKeys ||
      !Array.isArray(customerCaseKeys) ||
      (Array.isArray(customerCaseKeys) && customerCaseKeys.length === 0)
    ) {
      return [...CustomerVoiceInfos];
    }

    let resultlist = customerCaseKeys
      .map((linkKey) => {
        let matched = CustomerVoiceInfos.find((customerVoiceInfo) => {
          if (customerVoiceInfo.key === linkKey) {
            return true;
          }
          return false;
        });

        return matched;
      })
      .filter((item) => !!item);

    if (resultlist.length === 1) {
      return [resultlist[0], resultlist[0]];
    } else {
      return resultlist;
    }
  }, [customerCaseKeys]);

  const handleCloseModal = () => {
    setWillPlayYoutubeVideoId('');
  };

  const getTitle = () => {
    if (forServiceType) {
      return '';
    }
    if (whyChooseChowbusTitle) {
      return whyChooseChowbusTitle;
    }
    return intl.formatMessage({ id: 'home.chowbuspos.why_choose_chowbus' });
  };

  const onPlayChowbusOne = useCallback((youtubeVideoId) => {
    setWillPlayYoutubeVideoId(youtubeVideoId);
  }, []);

  return (
    <React.Fragment>
      <CommonSection
        title={getTitle()}
        outerClassName={JoinClassName(presentMdoel)}
        className={JoinClassName(
          indexStyles.whyChooseChowbusSection,
          forServiceType ? indexStyles.forServiceType : '',
          presentMdoel
        )}
      >
        <div className={JoinClassName(indexStyles.whyChooseChowbusGallery)}>
          <div className={JoinClassName(indexStyles.whyChooseChowbusGalleryyWrapper)}>
            <Carousel
              autoplay
              autoplaySpeed={5000}
              pauseOnDotsHover={true}
              centerPadding={centerPadding + 'px'}
              centerMode={true}
              arrows={false}
              afterChange={(current) => {
                onChangedActivedCustomerVoiceCardIndex(current);
              }}
              appendDots={(dots) => (
                <div
                  style={{
                    backgroundColor: 'transparent',
                    borderRadius: '0px',
                    padding: '10px',
                  }}
                >
                  <ul style={{ margin: '0px' }}> {dots} </ul>
                </div>
              )}
            >
              {customerVoiceInfos.map(
                (
                  { img, productLine, desc, restaurantLogo, restaurantName, location, link: linkRaw, youtubeVideoId },
                  index
                ) => {
                  let local_description = desc[local];
                  let local_restaurantName = restaurantName[local];
                  let local_location = location[local];
                  let link = (linkRaw ? linkRaw[local] : '') || linkRaw;

                  if (!link) {
                    return (
                      <div className={JoinClassName(indexStyles.whyChooseChowbusItemCardWrapper)} key={index}>
                        <div
                          style={{ height: '380px' }}
                          className={JoinClassName(indexStyles.whyChooseChowbusItemCardOuter)}
                        >
                          <div
                            className={JoinClassName(
                              indexStyles.whyChooseChowbusItemCard,
                              indexStyles.no_link,
                              ativeCustomerVoiceCardIndex === index ? indexStyles.whyChooseChowbusItemCardActived : ''
                            )}
                          >
                            <div className={JoinClassName(indexStyles.leftParty)}>
                              <p
                                className={JoinClassName(indexStyles.desc)}
                                dangerouslySetInnerHTML={{ __html: local_description }}
                              />
                              <div className={JoinClassName(indexStyles.restaurantInfo)}>
                                {restaurantLogo && (
                                  <div className={JoinClassName(indexStyles.restaurantLogo)}>
                                    <UnoptimizedImage
                                      src={restaurantLogo}
                                      layout="fill"
                                      objectFit="contain"
                                      alt={local_restaurantName}
                                    />
                                  </div>
                                )}

                                <div className={JoinClassName(indexStyles.restaurantLocation)}>
                                  <span>{local_restaurantName}</span>
                                  <span>{local_location}</span>
                                </div>
                              </div>
                            </div>

                            <div className={JoinClassName(indexStyles.rightParty)}>
                              <UnoptimizedImage src={img} layout="fill" objectFit="cover" alt={local_restaurantName} />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div className={JoinClassName(indexStyles.whyChooseChowbusItemCardWrapper)} key={index}>
                      {/* <FrozenRatio ratio={330 / 981}> */}
                      <div
                        style={{ height: '380px' }}
                        className={JoinClassName(indexStyles.whyChooseChowbusItemCardOuter)}
                      >
                        <div
                          className={JoinClassName(
                            indexStyles.whyChooseChowbusItemCard,
                            ativeCustomerVoiceCardIndex === index ? indexStyles.whyChooseChowbusItemCardActived : ''
                          )}
                        >
                          <div className={JoinClassName(indexStyles.leftParty)}>
                            <p
                              className={JoinClassName(indexStyles.desc)}
                              dangerouslySetInnerHTML={{ __html: local_description }}
                            />

                            <Link href={link} alt={local_restaurantName} key={index}>
                              <a target="_blank">
                                <spna className={JoinClassName(indexStyles.seefullstoryLink)}>
                                  {local === 'en' ? 'See Full Story' : '查看详情'}
                                </spna>
                              </a>
                            </Link>

                            <div className={JoinClassName(indexStyles.restaurantInfo)}>
                              {restaurantLogo && (
                                <div className={JoinClassName(indexStyles.restaurantLogo)}>
                                  <UnoptimizedImage
                                    src={restaurantLogo}
                                    layout="fill"
                                    objectFit="contain"
                                    alt={local_restaurantName}
                                  />
                                </div>
                              )}

                              <div className={JoinClassName(indexStyles.restaurantLocation)}>
                                <span>{local_restaurantName}</span>
                                <span>{local_location}</span>
                              </div>
                            </div>
                          </div>

                          <div className={JoinClassName(indexStyles.rightParty)}>
                            <UnoptimizedImage src={img} layout="fill" objectFit="cover" alt={local_restaurantName} />
                            {youtubeVideoId && (
                              <div
                                className={JoinClassName(indexStyles.playButton)}
                                onClick={() => onPlayChowbusOne(youtubeVideoId)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </Carousel>
          </div>
        </div>
      </CommonSection>
      {willPlayYoutubeVideoId && (
        <PlayerInModal onClose={handleCloseModal} playerId={'chowbus_one_player'} videoId={willPlayYoutubeVideoId} />
      )}
    </React.Fragment>
  );
};

export default WhyChooseChowbus;
