import { POS_WEB_SITE_CONTEXT_PATH } from 'src/consts/resource';
const CONTEXT_PATH = POS_WEB_SITE_CONTEXT_PATH;
const CustomerVoiceInfos = [
  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-xianghunan.jpeg`,
    productLine: 'Third-party Integration',
    desc: {
      zh: `“Chowbus POS的软件开发和硬件都非常先进. 而且整套系统是为中餐厅定制的, 所以我们的员工学习起来都非常容易上手. 我们从外卖开始就一直和Chowbus合作, 他们的服务很有耐心, 回复速度也非常快. 选择Chowbus我从不后悔.”`,
      en: `“With Chowbus, a local rep physically came to my store, set up the whole system including the POS and four printers, and stayed for a couple of days to make sure all my employees were well trained. You can’t find that kind of service anywhere else.”`,
    },
    restaurantName: { zh: '天天湘上', en: 'Xiang’s Hunan kitchen' },
    location: { zh: `波士顿`, en: 'Boston, MA' },
    link: `${CONTEXT_PATH}/customer-case/full-service/xiangs-hunan-kitchen`,
    key: `/full-service/xiangs-hunan-kitchen`,
  },
  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-xianghunan.jpeg`,
    productLine: 'Third-party Integration',
    desc: {
      zh: `“Chowbus POS的软件开发和硬件都非常先进. 而且整套系统是为中餐厅定制的, 所以我们的员工学习起来都非常容易上手. 我们从外卖开始就一直和Chowbus合作, 他们的服务很有耐心, 回复速度也非常快. 选择Chowbus我从不后悔.”`,
      en: `“I never regret a day that I chose to get Chowbus POS. They listen to our needs and they act fast.”`,
    },
    restaurantName: { zh: '天天湘上', en: 'Xiang’s Hunan kitchen' },
    location: { zh: `波士顿`, en: 'Boston, MA' },
    link: `${CONTEXT_PATH}/customer-case/full-service/xiangs-hunan-kitchen`,
    key: `/for_home/full-service/xiangs-hunan-kitchen`,
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-xianghunan.jpeg`,
    productLine: 'QR Code Ordering',
    desc: {
      zh: `“在使用Chowbus POS之前，一个服务员只能同时服务3桌顾客。有了扫码点餐后，服务员可以同时服务10桌顾客。”`,
      en: `“With QR code ordering, a waitress who waited 3 tables before can now wait 10 tables and maintain the quality interaction with customers at the same time.”`,
    },
    restaurantName: { zh: '天天湘上', en: 'Xiang’s Hunan kitchen' },
    location: { zh: `波士顿`, en: 'Boston, MA' },
    link: `${CONTEXT_PATH}/customer-case/full-service/xiangs-hunan-kitchen`,
    key: `/qrcode-ordering/full-service/xiangs-hunan-kitchen`,
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-xianghunan.jpeg`,
    productLine: '3rd Party Integrations',
    desc: {
      zh: `“Chowbus POS的软件开发和硬件都非常先进. 而且整套系统是为中餐厅定制的, 所以我们的员工学习起来都非常容易上手. 我们从外卖开始就一直和Chowbus合作, 他们的服务很有耐心, 回复速度也非常快. 选择Chowbus我从不后悔.”`,
      en: `“Chowbus POS automates the flow of third-party orders to the POS systems. No more manual re-entry, tablet madness, or unnecessary delays. I can finally minimize room for error and freeing our staff to focus more on in-person with our guests.”`,
    },
    restaurantName: { zh: '天天湘上', en: 'Xiang’s Hunan kitchen' },
    location: { zh: `波士顿`, en: 'Boston, MA' },
    link: `${CONTEXT_PATH}/customer-case/full-service/xiangs-hunan-kitchen`,
    key: `/third-party-integration/full-service/xiangs-hunan-kitchen`,
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-machimachi.jpeg`,
    productLine: 'Loyalty',
    desc: {
      zh: `“Chowbus的智能自助点餐机帮我们解决了排长队点单的痛点。”`,
      en: `“Historically, one of our biggest pain points is that sometimes we have a long queue of customers waiting to place their orders and thankfully Chowbus Kiosk has helped us solve this issue."`,
    },
    restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/machi_machi.png`,
    restaurantName: { zh: 'Machi Machi', en: 'Machi Machi' },
    location: { zh: '纽约', en: 'New York, NY' },
    link: `${CONTEXT_PATH}/customer-case/boba-tea-dessert/machi-machi`,
    key: `/boba-tea-dessert/machi-machi`,
    youtubeVideoId: 'dCCWNWh59vA',
  },
  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-machimachi.jpeg`,
    productLine: 'Loyalty',
    desc: {
      zh: `“会员充值后会收到通知短信。另外，如果会员有一段时间没有来消费，我们也可以发信息告诉他们我们最新的优惠活动，吸引他们回来消费。”`,
      en: `“Loyal members will receive a SMS notification for the amount added and the current balance. If a customer has not visited your restaurant in a while, the Loyalty Program can send them a special promotion to increase their chances of coming back.”`,
    },
    restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/machi_machi.png`,
    restaurantName: { zh: 'Machi Machi', en: 'Machi Machi' },
    location: { zh: '纽约', en: 'New York, NY' },
    link: `${CONTEXT_PATH}/customer-case/boba-tea-dessert/machi-machi`,
    youtubeVideoId: 'dCCWNWh59vA',
    key: `/loyalty/boba-tea-dessert/machi-machi`,
  },
  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-shinyaramen_2.jpeg`,
    productLine: 'QR Code',
    desc: {
      zh: `“使用Chowbus POS之后，我们的店员有更多的时间更好地服务顾客、沟通交流，平均小费收入也提升了将近20%。”`,
      en: `“Our average tip percentage was increased almost 20% because waiters and waitresses can now focus on table touching and making personal connections with the guests.”`,
    },
    restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/shinya_ramen.jpeg`,
    restaurantName: { zh: '深夜食堂', en: 'Shinya Ramen' },
    location: { zh: '芝加哥', en: 'Chicago, IL' },
    link: `${CONTEXT_PATH}/customer-case/sushi-ramen/shinya-ramen-house`,
    youtubeVideoId: 'HccE32BVvzg',
    key: `/sushi-ramen/shinya-ramen-house`,
  },
  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-shinyaramen_2.jpeg`,
    productLine: '3rd Party Integrations',
    desc: {
      zh: `“以前我们需要安排一个服务员在柜台将各个平台的外卖订单导入到POS中。使用Chowbus POS后，所有外卖订单自动导入到POS系统，服务员可以集中在服务堂食顾客上。”`,
      en: `"Before, during peak hours, we had to assign one employee only to stand in front of POS and all other tablets to deploy orders to the kitchen. By switching to Chowbus POS, we can now assign this employee to do table touching and bring orders to guests.”`,
    },
    restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/shinya_ramen.jpeg`,
    restaurantName: { zh: '深夜食堂', en: 'Shinya Ramen' },
    location: { zh: '芝加哥', en: 'Chicago, IL' },
    link: `${CONTEXT_PATH}/customer-case/sushi-ramen/shinya-ramen-house`,
    key: `/third-party-integration/sushi-ramen/shinya-ramen-house`,
    youtubeVideoId: 'HccE32BVvzg',
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-jongrobbq.jpeg`,
    productLine: 'Handheld',
    desc: {
      zh: `“有了POS手持设备后，我们的餐厅经理和服务员可以随时随地结账，还可以修改订单。”`,
      en: `"With the POS Handheld, manager and staff are now able to take payments anytime, anywhere, also make any and all changes online - whether in the restaurant, at home, or on the go."`,
    },
    restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/jongrobbq.png`,
    restaurantName: { zh: `Jongro BBQ`, en: `Jongro BBQ` },
    location: { zh: '纽约', en: 'New York' },
    link: `${CONTEXT_PATH}/customer-case/korean-bbq/jongro-bbq`,
    key: `/korean-bbq/jongro-bbq`,
  },
  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-jongrobbq.jpeg`,
    productLine: 'Handheld',
    desc: {
      zh: `“我们很喜欢Chowbus POS的服务团队。有问题可以随时联系他们，技术服务团队会在30分钟内上门解决问题。”`,
      en: `Jongro BBQ, an authentic experience to Korean BBQ, has been using Chowbus POS and POS Handheld for an improved customer and staff experience.`,
    },
    restaurantName: { zh: `Jongro BBQ`, en: `Jongro BBQ` },
    location: { zh: '纽约', en: 'New York' },
    link: `${CONTEXT_PATH}/customer-case/korean-bbq/jongro-bbq`,
    key: `/for-home/korean-bbq/jongro-bbq`,
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-kajiken.jpeg`,
    productLine: 'Point of Sale',
    desc: {
      zh: `“我很喜欢Chowbus的销售报表。我可以知道餐厅每月挣了多少钱。我还可以知道每个服务员的小费收入。”`,
      en: `“I can keep track of the first day of the month until the last day of the month. The sales report gives me a general idea of how much the profits I have made for the entire month. I can also track individual tips for individual servers.”`,
    },
    restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/kajiken.png`,
    restaurantName: { zh: `歌志轩`, en: `KAJIKEN` },
    location: { zh: '巴尔的摩', en: 'Baltimore' },
    link: `${CONTEXT_PATH}/customer-case/sushi-ramen/kajiken`,
    key: `/point-of-sale/sushi-ramen/kajiken`,
    youtubeVideoId: 'oA6w7EdbfyM',
  },
  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-kajiken.jpeg`,
    productLine: 'Tablet Ordering',
    desc: {
      zh: `“Chowbus改变了我们之前手动记录点菜餐品的点餐方式。顾客下单后，订单直达后厨，大大提升了出餐速度，降低错误。”`,
      en: `“One of our biggest issues was trying to have servers write everything down and remember everything. The Chowbus tablet ordering has smoothed out everything in the restaurant. The food comes out quicker, tickets get to the kitchen faster, and there are fewer errors.”`,
    },
    restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/kajiken.png`,
    restaurantName: { zh: `歌志轩`, en: `KAJIKEN` },
    location: { zh: '巴尔的摩', en: 'Baltimore' },
    link: `${CONTEXT_PATH}/customer-case/sushi-ramen/kajiken`,
    key: `/tablet_ordering/sushi-ramen/kajiken`,
    youtubeVideoId: 'oA6w7EdbfyM',
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-businesstype_kajiken.jpg`,
    productLine: 'Tablet Ordering',
    desc: {
      zh: `““在使用Chowbus POS之前，我们最大的痛点之一就是店员需要手动记录所有的餐品。在使用Chowbus的平板点餐之后，点餐变得更加十分简单、便捷。”`,
      en: `“One of our biggest issues was trying to have servers write everything down and remember everything. The Chowbus tablet ordering has smoothed out everything in the restaurant. The food comes out quicker, tickets get to the kitchen faster, and there are fewer errors.”`,
    },
    restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/kajiken.png`,
    restaurantName: { zh: `歌志轩`, en: `KAJIKEN` },
    location: { zh: '巴尔的摩', en: 'Baltimore' },
    link: `${CONTEXT_PATH}/customer-case/sushi-ramen/kajiken`,
    key: `/business-type/sushi-ramen/kajiken`,
    youtubeVideoId: 'oA6w7EdbfyM',
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-shinyaramen_2.jpeg`,
    productLine: 'QR Code',
    desc: {
      zh: `通过使用无接触点餐, 深夜食堂的翻桌率提高了 30%, 并减少了三个人工. “我们的一些服务员担心, 通过使用二维码, 他们不会像以前那样得到那么多小费.” Shinya Ramen 的老板 Joey 告诉我们, “这种担忧很快就被打消了.
        我们的平均小费百分比提高了近35%, 因为服务员们现在可以专注于客人需要什么并且与客人建立友好关系.”`,
      en: `By adopting contactless QR code ordering, Shinya Ramen increased the table turnover rate by 30% and was able to cut two more headcounts. 
        “Some of our waiters and waitresses worry that by using QR code,  they would not get as much as tips as before. "Shinya Ramen's co-owner Joey told us,  
        “The concern was quickly dispelled. Our average tip percentage was increased almost 35% because waiters and waitresses can now focus on table  
        touching and making personal connections with the guests."`,
    },
    restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/shinya_ramen.jpeg`,
    restaurantName: { zh: '深夜食堂', en: 'Shinya Ramen' },
    location: { zh: '芝加哥', en: 'Chicago, IL' },
    link: `${CONTEXT_PATH}/customer-case/sushi-ramen/shinya-ramen-house`,
    key: `/sushi-ramen/shinya-ramen-house`,
    youtubeVideoId: 'HccE32BVvzg',
  },
  {
    img: `${process.env.ASSETS_PREFIX}/home/customervoice/customer-voice-teamobobabar.jpeg`,
    productLine: 'Kiosk',
    desc: {
      zh: `“Chowbus真的很关心餐厅和顾客的需求，非常符合我们的理念。”`,
      en: `“I can tell they(Chowbus team) really care about customers, which aligns with our mission.”`,
    },
    restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/teamo.png`,
    restaurantName: { zh: `享茶`, en: `Te'amo Boba Bar` },
    location: { zh: '芝加哥', en: 'Chicago, IL' },
    link: `${CONTEXT_PATH}/customer-case/boba-tea-dessert/teamo-boba-bar`,
    key: `/boba-tea-dessert/teamo-boba-bar`,
    youtubeVideoId: 'Tw_MCuODA8U',
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/Mr_gu_video_cover.png`,
    productLine: 'Online Ordering',
    desc: {
      zh: `“疫情期间，餐厅拉新和留住老客十分重要。Chowbus POS和他们的网页订单帮助我们召回老客。”`,
      en: `During COVID-19, it’s more important than ever to grow and foster your restaurant’s ultimate fans. Chowbus POS and the Online Ordering helps Mrs Gu Skewers Hot Pot keep their guests coming back.`,
    },
    restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/gangguanwuchang.png`,
    restaurantName: { zh: `顾大姐串串香火锅`, en: `Mrs. Gu` },
    location: { zh: '芝加哥', en: 'Chicago, IL' },
    link: `${CONTEXT_PATH}/customer-case/hot-pot/mrs-gu`,
    key: `/hot-pot/mrs-gu`,
    youtubeVideoId: '3ctvsPY4pl0',
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/Mr_gu_video_cover.png`,
    productLine: 'QR Code Ordering',
    desc: {
      zh: `“有了扫码点餐后，我们不用花时间跟顾客解释菜品。顾客在下单后还能随时加菜，大大增加了客单价。另外，多人扫码点餐还可以实时更新，不会出现重复点餐的情况。”`,
      en: `“QR Code Ordering helped us save time to explain the dishes to customers. Customers can still add dishes after an order is placed whenever they want, which helped us increase the order size a lot. Plus, everyone can see what others have added in real-time so that it could avoid double ordering. ”`,
    },
    restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/gangguanwuchang.png`,
    restaurantName: { zh: `顾大姐串串香火锅`, en: `Mrs. Gu` },
    location: { zh: '芝加哥', en: 'Chicago, IL' },
    link: `${CONTEXT_PATH}/customer-case/hot-pot/mrs-gu`,
    key: `/qrcode-ordering/hot-pot/mrs-gu`,
    youtubeVideoId: '3ctvsPY4pl0',
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/shu_da_xia_hotpot_video_cover.jpeg`,
    productLine: 'hot-pot',
    desc: {
      zh: `“平板点餐帮助我们减少顾客等位时间和投诉，大大提升了顾客用餐体验。”`,
      en: `"Tablet ordering has greatly helped us improve customer service. This allows us to serve them immediately upon seating, greatly reducing customer wait times and complaints.”`,
    },
    // restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/gangguanwuchang.png`,
    restaurantName: { zh: `蜀大侠火锅`, en: `Shu Da Xia Hotpot` },
    location: { zh: '波士顿', en: 'Boston' },
    link: `${CONTEXT_PATH}/customer-case/hot-pot/shu-da-xia-hotpot`,
    key: `/hot-pot/shu-da-xia-hotpot`,
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/xiao_long_kan_video_cover.jpg`,
    productLine: 'hot-pot',
    desc: {
      zh: `“Chowbus POS平板点餐彻底改变了餐馆的点餐体验。我们的客人很喜欢自助点餐的体验。这大大减少了用餐等待时间，我们也能服务更多的顾客。”`,
      en: `"Chowbus POS Tablet Ordering has been a game-changer for our restaurant. Our guests appreciate the convenience and control they have over their dining experience. It has significantly reduced waiting times and enabled us to serve more customers without compromising on quality."`,
    },
    restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/xiaolongkai.png`,
    restaurantName: { zh: `小龙坎`, en: `Xiao Long Kan` },
    location: { zh: '芝加哥', en: 'Chicago' },
    link: `${CONTEXT_PATH}/customer-case/hot-pot/xiao-long-kan`,
    key: `/hot-pot/xiao-long-kan`,
  },
  {
    img: `${process.env.ASSETS_PREFIX}/home/xiao_long_kan_video_cover.jpg`,
    productLine: 'hot-pot',
    desc: {
      zh: `“Chowbus的手持POS设备改变了我们点单、结账的方式，加快了我们整体服务速度，显著提升了20%的翻桌率。”`,
      en: `"Chowbus POS Tablet Ordering has been a game-changer for our restaurant. Our guests appreciate the convenience and control they have over their dining experience. It has significantly reduced waiting times and enabled us to serve more customers without compromising on quality."`,
    },
    restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/xiaolongkai.png`,
    restaurantName: { zh: `小龙坎`, en: `Xiao Long Kan` },
    location: { zh: '芝加哥', en: 'Chicago' },
    link: `${CONTEXT_PATH}/customer-case/hot-pot/xiao-long-kan`,
    key: `/handheld/hot-pot/xiao-long-kan`,
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/liuyishou_hotpot_video_cover.jpg`,
    productLine: 'hot-pot',
    desc: {
      zh: `“Chowbus POS的24/7小时上门服务让我们很安心。”`,
      en: `“Chowbus POS's 24/7 onsite client service has been invaluable to us.”`,
    },
    // restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/gangguanwuchang.png`,
    restaurantName: { zh: `刘一手火锅`, en: `Liuyishou Hotpot` },
    location: { zh: '芝加哥', en: 'Chicago' },
    link: `${CONTEXT_PATH}/customer-case/hot-pot/liuyishou-hotpot`,
    key: `/hot-pot/liuyishou-hotpot`,
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/liuyishou_hotpot_video_cover.jpg`,
    productLine: 'loyalty',
    desc: {
      en: `"Chowbus POS has made it seamless for us to get customer segmentation, track customer visits and ensure they receive the recognition they deserve.”`,
      zh: `“Chowbus POS让我们可以很轻松地针对不同阶段的会员发送营销短信。”`,
    },
    // restaurantLogo: `${process.env.ASSETS_PREFIX}/home/clients-logo/gangguanwuchang.png`,
    restaurantName: { zh: `刘一手火锅`, en: `Liuyishou Hotpot` },
    location: { zh: '芝加哥', en: 'Chicago' },
    link: `${CONTEXT_PATH}/customer-case/hot-pot/liuyishou-hotpot`,
    key: `/loyalty/hot-pot/liuyishou-hotpot`,
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/liuyishou_hotpot_video_cover.jpg`,
    productLine: 'waitlist',
    desc: {
      en: `"With the Waitlist feature, our guests can easily join our waitlist system through Chowbus Waitlist App, Google Waitlist and our restaurant website reservation anywhere and anytime. We can seat more diners, manage the line without staff. "`,
      zh: `“有了等位功能后，顾客可以在店里或者随时随地谷歌等位、网页加入排队。我们可以接待更多的顾客，也不用花费时间管理排队进程。”`,
    },
    restaurantName: { zh: `刘一手火锅`, en: `Liuyishou Hotpot` },
    location: { zh: '芝加哥', en: 'Chicago' },
    link: `${CONTEXT_PATH}/customer-case/hot-pot/liuyishou-hotpot`,
    key: `/waitlist/hot-pot/liuyishou-hotpot`,
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/chowbus-pos-case-study-er-hotpot-manager-recommendation.jpg`,
    productLine: 'kiosk',
    desc: {
      zh: `“外带餐品的顾客可以直接在自助点餐机点餐。餐厅无需安排服务员帮助其点餐。即使在高峰时段，也不会出现人手短缺的问题，我们也可以服务更多的客户。”`,
      en: `“Take-out customers can place orders directly at the kiosk, reducing the need for staff assistance. Even during peak hours, labor shortages are no longer an issue, and the restaurant can serve more customers.”`,
    },
    restaurantName: { zh: `贰火锅`, en: `Er Hotpot` },
    location: { zh: '纽约', en: 'New York' },
    key: `/kiosk/hot-pot/er-hotpot`,
    youtubeVideoId: 'tZsAl_W8E48',
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/chowbus-pos-case-study-er-hotpot-manager-recommendation.jpg`,
    productLine: 'Loyalty',
    desc: {
      zh: `“我们使用充值入会的方式，并提供充值返现、首单折扣、会员零元菜等会员福利。并且会员信息在集团内通用，会员可以在任意店消费会员卡充值金额，还能享受会员零元菜等福利。”`,
      en: `“We offer a top-up membership program with perks like cashback, discounts, and free dishes. Membership information is shared across all branches, allowing members to use their prepaid balances at any restaurant and enjoy membership benefits.”`,
    },
    restaurantName: { zh: `贰火锅`, en: `Er Hotpot` },
    location: { zh: '纽约', en: 'New York' },
    // link: `https://pos.chowbus.com/blog/article/restaurant-pos-case-study-hot-pot-save-labor`,
    link: {
      zh: `https://pos.chowbus.com/zh/blog/article/restaurant-pos-case-study-hot-pot-save-labor`,
      en: `https://pos.chowbus.com/blog/article/restaurant-pos-case-study-hot-pot-save-labor`,
    },
    key: `/loyalty/hot-pot/er-hotpot`,
    youtubeVideoId: 'tZsAl_W8E48',
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/chowbus-pos-case-study-er-hotpot-manager-recommendation.jpg`,
    productLine: 'Loyalty',
    desc: {
      zh: `“Chowbus帮我节省一半的人工！未来，贰火锅集团的新店会继续使用Chowbus产品。也希望Chowbus能持续开发出更新、更能帮助餐饮人的功能”`,
      en: `“Chowbus helps save half of the labor costs! In the future, Er Hot Pot Group's new restaurants will continue to use Chowbus products."`,
    },
    restaurantName: { zh: `贰火锅`, en: `Er Hotpot` },
    location: { zh: '纽约', en: 'New York' },
    // link: `https://pos.chowbus.com/blog/article/restaurant-pos-case-study-hot-pot-save-labor`,
    link: {
      zh: `https://pos.chowbus.com/zh/blog/article/restaurant-pos-case-study-hot-pot-save-labor`,
      en: `https://pos.chowbus.com/blog/article/restaurant-pos-case-study-hot-pot-save-labor`,
    },
    key: `/hot-pot/er-hotpot`,
    youtubeVideoId: 'tZsAl_W8E48',
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/mala_sichuan_bistro_cover.jpg`,
    // image: `${process.env.ASSETS_PREFIX}/home/mala_sichuan_bistro_cover.jpg`,
    productLine: 'RoTech Robot',
    desc: {
      zh: `“我真的很喜欢Chowbus的手持POS设备。我们的服务员可以在桌边帮顾客点餐和结账。用了Chowbus之后，我们的翻桌率提升了30%。顾客也对桌边支付体验十分满意。”`,
      en: `“I really love Chowbus's handheld iPad. Our staff can take orders and process payments for customers right at the table.Since using Chowbus, our table turnover rate has increased by nearly 30%, and this simplified payment method is also highly appreciated by our customers.”`,
    },
    restaurantName: { zh: `小熊川菜`, en: `Mala Sichuan Bistro` },
    location: { zh: '休斯敦', en: 'Houston' },
    link: `${CONTEXT_PATH}/customer-case/full-service/mala-sichuan-bistro`,
    key: `/hot-pot/xiaoxiong-chuancai`,
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/mala_sichuan_bistro_cover.jpg`,
    productLine: 'RoTech Robot',
    desc: {
      zh: `“用Chowbus集成了三方外卖平台之后，我们节省了外卖平台接单设备的费用并大大提升了效率。我们不再担心外卖订单会有延迟或者误单的问题。”`,
      en: `“The delivery integration has saved us from the expenses of multiple platform devices and significantly improved our operational efficiency. It has eliminated order loss and delays, and we no longer worry about delivery orders.”`,
    },
    restaurantName: { zh: `小熊川菜`, en: `Mala Sichuan Bistro` },
    location: { zh: '休斯敦', en: 'Houston' },
    link: `${CONTEXT_PATH}/customer-case/full-service/mala-sichuan-bistro`,
    key: `/third-party-integration/hot-pot/xiaoxiong-chuancai`,
  },

  {
    img: `${process.env.ASSETS_PREFIX}/home/dim-sum-kitchen_food_opera_cover.jpg`,
    productLine: 'dim_sum',
    desc: {
      zh: `“Chowbus POS帮我每月节省近万刀的人工成本！之前使用的POS系统和刷卡系统分属两家公司，经常出现两边金额对不上的情况。现在使用Chowbus POS后，账单金额十分精确，还能稳定应对每天上万刀的流水。”`,
      en: `"Chowbus POS helps me save nearly $10,000 in labor costs every month! Billing amounts are very accurate, and the system can steadily handle daily turnovers of tens of thousands of dollars. "`,
    },
    restaurantName: { zh: `喜临门大酒楼`, en: `Food Opera` },
    location: { zh: `波士顿`, en: 'Boston, MA' },
    link: {
      zh: `https://pos.chowbus.com/zh/blog/article/food-opera-dim-sum-pos-testimonial`,
      en: `https://pos.chowbus.com/blog/article/food-opera-dim-sum-pos-testimonial`,
    },
    key: `/dim_sum_pos`,
  },
];

export default CustomerVoiceInfos;
